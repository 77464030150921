



































































import { Component, Vue } from "vue-property-decorator";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

@Component({
  components: {
    SidebarMenu
  }
})
export default class SidebarNav extends Vue {
  stdMenu = [
    {
      href: "/",
      title: "Home",
      icon: {
        element: "font-awesome-icon",
        class: "vsm--icon",
        attributes: { icon: "home" }
      }
    },
    {
      href: "/contact",
      title: "Contact Us",
      icon: {
        element: "font-awesome-icon",
        class: "vsm--icon",
        attributes: { icon: "paper-plane" }
      }
    }
  ];

  collapsed = false;
  themes: [
    {
      name: "Default theme";
      input: "";
    },
    {
      name: "White theme";
      input: "white-theme";
    }
  ];
  selectedTheme: "white-theme";
  isOnMobile = false;

  get menuItems() {
    if (this.$store.state.hotelInfo)
      if (this.$store.state.hotelInfo.navigation) {
        const menu = this.$store.state.hotelInfo.navigation;
        return menu;
      }
    return this.stdMenu;
  }

  get sidebarOpen() {
    return this.$store.state.ui.sidebarOpen;
  }

  get hotelData() {
    return this.$store.state.hotelInfo;
  }

  goToBookingPage(e) {
    e.preventDefault();
    if (!this.$store.state.hotelInfo) return;
    const hotelData = this.$store.state.hotelInfo;
    const masterBookingUrl = process.env.VUE_APP_BOOKING_PAGE_URL;
    let bookingUrl = masterBookingUrl.replace("##HotelID##", hotelData.HotelID);
    if (hotelData.webToken) bookingUrl += `?k=${hotelData.webToken}`;
    window.open(bookingUrl, "_blank");
  }

  onToggleCollapse(collapsed) {
    this.collapsed = collapsed;
  }

  onItemClick(event, item) {
    if (item.child) {
      console.log(item);
      event.preventDefault();
      this.$store.commit("setUiSidebarStatus", true);
    }
  }

  onResize() {
    if (window.innerWidth <= 767) {
      this.isOnMobile = true;
      this.collapsed = true;
    } else {
      this.isOnMobile = false;
      this.collapsed = false;
    }
  }

  CloseSideBar() {
    this.$store.commit("setUiSidebarStatus", false);
  }
}
