import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAu_1D3aJ2Ro0q12PM__Lxi1egKOpfZm1E",
  authDomain: "guest-hub-216e0.firebaseapp.com",
  projectId: "guest-hub-216e0",
  storageBucket: "guest-hub-216e0.appspot.com",
  messagingSenderId: "614158742801",
  appId: "1:614158742801:web:031bfac058697fe1a288b0",
  measurementId: "G-K74BL3G64P"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const hotelsCollection = db.collection("HotelIDs");
const chats = db.collection("Chats");
const chatMessages = db.collection("ChatMessages");
export { db, auth, hotelsCollection, chats, chatMessages };
