































































import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Component({
  components: {
    HelloWorld
  }
})
export default class Home extends Vue {
  get hotelData() {
    const hotelData = this.$store.state.hotelInfo;
    if (hotelData && hotelData.photos) {
      hotelData.mainImage = hotelData.photos[0].completeUrl;
    }
    return hotelData;
  }

  callUs(hotelData) {
    if (hotelData.telephone) {
      const Link = `tel:${hotelData.telephone}`;
      window.location.href = Link;
    }
  }

  googleDirection(hotelData) {
    if (hotelData.formatAddress) {
      let googleLink = "https://www.google.com/maps/dir/Current+Location/";
      googleLink += hotelData.formatAddress;
      window.open(googleLink, "_blank");
    }
  }
}
