















import { Component, Vue } from "vue-property-decorator";
@Component
export default class HeaderMessages extends Vue {
  get hotelData() {
    return this.$store.state.hotelInfo;
  }
  get bookingData() {
    return this.$store.state.bookingInfo;
  }
  get chatMessages() {
    return this.$store.state.chat;
  }
  get showNotifications() {
    return this.$store.state.ui.showNotifications;
  }
  get showUnreadMessages() {
    return this.$store.state.ui.unreadMessages;
  }
}
