import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authProfile: null,
    hotelInfo: null,
    bookingInfo: null,
    guestKey: "",
    hotelKey: "",

    chat: [],
    ui: {
      loading: true,
      sidebarOpen: false,
      showNotifications: false,
      unreadMessages: false,
      pwaInstalled: false
    }
  },
  mutations: {
    setUnreadMessages(state, payload) {
      Vue.set(state.ui, "unreadMessages", payload);
    },
    setPwaInstalled(state, payload) {
      Vue.set(state.ui, "pwaInstalled", payload);
    },
    setNotifications(state, payload) {
      Vue.set(state.ui, "showNotifications", payload);
    },
    setAuthProfile(state, payload) {
      Vue.set(state, "authProfile", payload);
    },
    setGuestKey(state, payload) {
      Vue.set(state, "guestKey", payload);
    },
    setHotelKey(state, payload) {
      Vue.set(state, "hotelKey", payload);
    },
    setBookingInfo(state, payload) {
      Vue.set(state, "bookingInfo", payload);
      if (payload.guestKey) localStorage.setItem("guestKey", payload.guestKey);
    },
    setHotelInfo(state, payload) {
      Vue.set(state, "hotelInfo", payload);
      if (payload.hotelKey) localStorage.setItem("hotelKey", payload.hotelKey);
      if (!payload.hotelKey) localStorage.removeItem("hotelKey");
    },
    setChat(state, payload) {
      Vue.set(state, "chat", payload);
    },
    setChatMessages(state, payload) {
      Vue.set(state.chat, "messages", payload);
    },
    removeAuthProfile(state) {
      Vue.set(state, "authProfile", null);
      Vue.set(state, "bookingInfo", null);
      Vue.set(state, "chat", null);
      Vue.set(state, "guestKey", "");
      localStorage.removeItem("guestKey");
    },
    removeBookingInfo(state) {
      Vue.set(state, "bookingInfo", null);
    },
    setUILoading(state, truefalse) {
      Vue.set(state.ui, "loading", truefalse);
    },
    setUiSidebarStatus(state, truefalse) {
      Vue.set(state.ui, "sidebarOpen", truefalse);
    }
  },
  actions: {
    async initApp({ commit }) {
      //check if logged in
      if (fb.auth.currentUser) {
        commit("setAuthProfile", fb.auth.currentUser);
      }
      commit("setUILoading", false);
    },

    setHotelBookingInfo({ commit }, payload) {
      if (payload.hotel) commit("setHotelInfo", payload.hotel);
    },

    setGuestBookingInfo({ commit }, payload) {
      if (payload.hotel) commit("setHotelInfo", payload.hotel);
      if (payload.hotel) commit("setBookingInfo", payload.booking);
    }
  },
  modules: {}
});
