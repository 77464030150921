

















import { Component, Vue } from "vue-property-decorator";
import { isMobile, isAndroid } from "mobile-device-detect";
import VueAddtohomescreen from "@owliehq/vue-addtohomescreen";
Vue.use(VueAddtohomescreen);

@Component({})
export default class App extends Vue {
  isIphone = false;
  get hotelData() {
    const hotelData = this.$store.state.hotelInfo;
    if (hotelData && hotelData.photos) {
      hotelData.mainImage = hotelData.photos[0].completeUrl;
    }
    return hotelData;
  }

  get mobileType() {
    const pwa = this.$store.state.ui.pwaInstalled;
    if (pwa) return false;
    if (isMobile && !isAndroid) this.isIphone = true;
    return isMobile;
  }
}
