































































































import { Component, Vue } from "vue-property-decorator";
import HeaderNotifications from "@/components/includes/HeaderNotifications.vue";
import HeaderMessages from "@/components/includes/HeaderMessages.vue";
import HeaderProfile from "@/components/includes/HeaderProfile.vue";

@Component({
  components: {
    HeaderNotifications,
    HeaderMessages,
    HeaderProfile
  }
})
export default class Header extends Vue {
  slideToggle() {
    this.$store.commit("setUiSidebarStatus", !this.$store.state.ui.sidebarOpen);
  }

  get sidebarOpen() {
    return this.$store.state.ui.sidebarOpen;
  }

  get hotelData() {
    return this.$store.state.hotelInfo;
  }

  showSearchBar() {
    const pageBody = document.body;
    pageBody.classList.add("g-navbar-search-showing");
    pageBody.classList.remove("g-navbar-search-show");
    setTimeout(() => {
      pageBody.classList.add("g-navbar-search-show");
      pageBody.classList.remove("g-navbar-search-showing");
    }, 150);
    setTimeout(() => {
      pageBody.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearchBar() {
    const pageBody = document.body;
    setTimeout(() => {
      pageBody.classList.add("g-navbar-search-hiding");
      pageBody.classList.remove("g-navbar-search-show");
    }, 150);
    setTimeout(() => {
      pageBody.classList.add("g-navbar-search-hidden");
      pageBody.classList.remove("g-navbar-search-hiding");
    }, 300);
    setTimeout(() => {
      pageBody.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
}
