import axios from "axios";
import md5 from "md5";

const masterToken = process.env.VUE_APP_APIKEY;

// const httpClient = axios.create({
//   baseURL: "https://secure.hotels.uk.com/Core/API/WebApp/QHub"
// });

// Creates Header Auth Token
const buildUrlMD5 = config => {
  const tempUrl = [];
  if (config.baseURL) tempUrl.push(config.baseURL);
  if (config.url) tempUrl.push(config.url);
  if (tempUrl.length > 0) {
    const url = tempUrl.join("");
    const numbersOnly = url.replace(/\D/g, "");
    return md5(masterToken + numbersOnly);
  }
  return "";
};

// Applies Header Auth to every API Call
axios.interceptors.request.use(function(config) {
  const token = buildUrlMD5(config);
  config.headers.Auth = token;
  return config;
});

export default axios;
