






















































































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class HeaderNotifications extends Vue {
  get hotelData() {
    return this.$store.state.hotelInfo;
  }
  get bookingData() {
    return this.$store.state.bookingInfo;
  }
}
