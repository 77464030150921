









































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class CustomFooter extends Vue {
  get hotelData() {
    return this.$store.state.hotelInfo;
  }
}
