




































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class HeaderProfile extends Vue {
  get hotelInfo() {
    return this.$store.state.hotelInfo;
  }

  get guestKey() {
    return localStorage.getItem("guestKey");
  }

  get authUser() {
    return this.$store.state.authProfile;
  }
}
