import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import * as fb from "@/firebase";
import store from "@/store";
import axios from "@/axios";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/hotel",
    name: "Hotel",
    component: () => import("../views/HotelInfo.vue")
  },
  {
    path: "/before-arrival",
    name: "beforeArrival",
    component: () => import("../views/beforeArrival/Info.vue")
  },
  {
    path: "/before-arrival/:subPage",
    name: "beforeArrivalSubPages",
    component: () => import("../views/beforeArrival/Info.vue")
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/guest/notifications",
    name: "GuestNotifications",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/guest/GuestNotifications.vue")
  },
  {
    path: "/guest/messages",
    name: "GuestMessages",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/guest/GuestMessages.vue")
  },
  {
    path: "/guest",
    name: "GuestInfo",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/guest/GuestInfo.vue")
  },
  {
    path: "/guest/:UrlKey",
    name: "GuestInfoBooking",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/guest/GuestInfoBooking.vue")
  },
  {
    path: "/login",
    name: "Sign In",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/lost-password",
    name: "LostPassword",
    component: () => import("../views/LostPassword.vue")
  },
  {
    path: "/logout",
    name: "Logout"
  },
  {
    path: "/:HotelUrlKey",
    name: "HomeKey",
    component: Home
  }
];

const setGuestKeyState = () => {
  const guestKey = localStorage.getItem("guestKey");
  if (guestKey) store.commit("setGuestKey", guestKey);
};
const setHotelKeyState = () => {
  const hotelKey = localStorage.getItem("hotelKey");
  if (hotelKey) store.commit("setHotelKey", hotelKey);
};

const setHotelPwaState = () => {
  const pwa = localStorage.getItem("pwa");
  if (pwa) store.commit("setPwaInstalled", pwa);
};

const storeExtraData = to => {
  const guestArea = ["GuestNotifications", "GuestInfoBooking", "GuestMessages"];
  if (guestArea.includes(to.name)) {
    store.commit("setNotifications", true);
  } else {
    store.commit("setNotifications", false);
  }
};

const getHotelData = (to, next) => {
  const HotelUrlKey = to.params.HotelUrlKey;
  if (HotelUrlKey) {
    const OldHotelKey = localStorage.getItem("hotelKey");
    const Url = `${process.env.VUE_APP_APIURL}/hotelinfo/${HotelUrlKey}`;
    const data = {};
    axios.post(Url, data).then(response => {
      store.dispatch("setHotelBookingInfo", response.data).then(() => {
        store.commit("setUILoading", false);
        if (OldHotelKey && OldHotelKey !== HotelUrlKey) {
          fb.auth.signOut();
          store.commit("removeAuthProfile");
        }
        next();
      });
    });
  } else if (!store.state.hotelInfo) {
    const hotelKey = localStorage.getItem("hotelKey");
    if (hotelKey) {
      const Url = `${process.env.VUE_APP_APIURL}/hotelinfo/${hotelKey}`;
      const data = {};
      axios.post(Url, data).then(response => {
        store.dispatch("setHotelBookingInfo", response.data);
        next();
      });
    }
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit("setUiSidebarStatus", false);
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (to.name === "Logout") {
    fb.auth.signOut();
    store.commit("removeAuthProfile");
    next("/");
  }
  if (to.name === "Login" && fb.auth.currentUser) {
    next("/");
  }
  if (requiresAuth && !fb.auth.currentUser) {
    const guestBooking = to.params.UrlKey || null;
    if (guestBooking) {
      localStorage.setItem("guestKey", guestBooking);
    }
    next("/login");
  } else {
    next();
  }
  setGuestKeyState();
  setHotelKeyState();
  setHotelPwaState();
  storeExtraData(to);
  getHotelData(to, next);
});

export default router;
