












import { Component, Vue, Watch } from "vue-property-decorator";
import CustomSidebarNav from "@/components/CustomSidebarNav.vue";
import Header from "@/components/Header.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import PromptInstall from "@/components/PromptInstall.vue";
import { chats, chatMessages } from "@/firebase.js";
import cssVars from "css-vars-ponyfill";

@Component({
  components: {
    CustomSidebarNav,
    Header,
    CustomFooter,
    PromptInstall
  }
})
export default class Home extends Vue {
  get uiLoading() {
    return this.$store.state.ui.loading;
  }

  get hotelData() {
    return this.$store.state.hotelInfo;
  }

  @Watch("hotelData", { deep: true, immediate: true })
  customTheme(hotelInfo) {
    let themeColor = "#4e5a5a";
    let themeDarkerColor = "#373f3f";
    let themeTextColor = "#9aadc2";
    let themeColor90 = "rgba(78, 90, 90, 0.9)";
    let themeColor60 = "rgba(78, 90, 90, 0.6)";
    let themeDarkerDropDown = "rgba(55, 63, 63, 0.95)";
    if (hotelInfo && hotelInfo.Settings) {
      themeColor = hotelInfo.Settings.themecolor;
      themeTextColor =
        hotelInfo.Settings.themetype == "dark" ? "#f7fafc" : "#222";
      if (hotelInfo.Settings.rgba) {
        const rgba = hotelInfo.Settings.rgba;
        const darkerRgba = hotelInfo.Settings.themeDarkerRgba;
        themeColor90 = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.9)`;
        themeColor60 = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.6)`;
        themeDarkerDropDown = `rgba(${darkerRgba.r}, ${darkerRgba.g}, ${darkerRgba.b}, 0.95)`;
        themeDarkerColor = hotelInfo.Settings.themeDarkerColor;
      }
    }
    cssVars({
      variables: {
        "--themeColor": themeColor,
        "--themeColorDarker": themeDarkerColor,
        "--themeDarkerDropDown": themeDarkerDropDown,
        "--themeTextColor": themeTextColor,
        "--themeColor90": themeColor90,
        "--themeColor60": themeColor60
      }
    });
  }

  get isLoginPage() {
    const page = this.$route.path;
    const pageArray = ["login", "lost-password"];
    const includeArray = [];
    for (let i = 0; i < pageArray.length; i++) {
      const includes = page.includes(pageArray[i]);
      if (includes) includeArray.push(includes);
    }
    if (includeArray.length > 0) return true;
    return false;
  }

  async hasGotMessages() {
    await chats
      .where("live", "==", true)
      .limit(1)
      .get()
      .then(docs => {
        const chatsArray = [];
        docs.forEach(mess => {
          const chat = mess.data();
          chat.id = mess.id;
          chat.messages = [];
          chatsArray.push(chat);
        });
        this.$store.commit("setChat", chatsArray[0]);
        chatMessages
          .where("ChatID", "==", "HMsN46jDg0ARuXBVOZGN")
          .onSnapshot(messages => {
            const chatMessagesArray = [];
            messages.forEach(mess => {
              const chatMess = mess.data();
              chatMess.id = mess.id;
              chatMessagesArray.push(chatMess);
            });
            this.$store.commit("setChatMessages", chatMessagesArray);
          });
      });
  }

  created() {
    this.$store.dispatch("initApp");
    //this.hasGotMessages();
  }
  mounted() {
    document.body.classList.add("home");
  }
  destroyed() {
    document.body.classList.remove("home");
  }
}
